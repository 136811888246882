import React, { ReactNode } from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "@/components/Card";
import { StyledBadge, StyledIconButton } from "@/components/styled";
import { getFileStorageBadgeVariant } from "@/utils/getIncentiveBadgeVariant.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { getEnrollmentBadgeVariant } from "@/utils/getEnrollmentBadgeVariant.ts";
import { useProjectsStore } from "@/store/projectsStore.ts";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import EditProjectModal from "@/pages/ProjectDetailsPage/components/EditProjectModal.tsx";
import BadgeSelect from "@/pages/admin-pages/UserManagementPage/components/BadgeSelect.tsx";
import { INCENTIVE_STATUS_ITEMS } from "@/constants";
import { replaceUnderscoresWithSpaces } from "@/utils/replaceUnderscoreWithSpaces.ts";
import SVGGoTo from "@/assets/icons/SVGGoTo.tsx";
import { downloadFile } from "@/utils/downloadFile.ts";
import { useAuthStore } from "@/store/authStore.ts";
import { allowEditProject } from "@/utils/allowEditProject.ts";
import { calculateUsableBatteryCapacity } from "@/utils/calculateUsableBatteryCapacity.ts";
import dayjs from "dayjs";

export const BoxInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "40px",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ProjectInfoCard = ({ getFilesReq }: { getFilesReq: () => void }) => {
  const projectDetails = useProjectsStore((state) => state.projectDetails);
  const getProjectDetails = useProjectsStore(
    (state) => state.getProjectDetails,
  );
  const role = useAuthStore((state) => state.role);

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card sx={{ position: "relative" }}>
      <Box
        sx={{ display: "flex", gap: "12px", justifyContent: "space-between" }}
      >
        <BoxInfoWrapper sx={{ overflow: "hidden" }}>
          <ProjectInfoItem title={"ID"}>
            {projectDetails?.id?.toString()?.padStart(3, "0")}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Customer name"}>
            {projectDetails?.customerName}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Address"}>
            {projectDetails?.address}
          </ProjectInfoItem>
          <ProjectInfoItem title={"City"}>
            {projectDetails?.city}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Zip code"}>
            {projectDetails?.zipCode}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Account #"}>
            {projectDetails?.accountHash}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Customer email"}>
            {projectDetails?.accountEmail}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Existing inverter"}>
            {projectDetails?.existingInverter}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Existing Solar - kW-DC"}>
            {projectDetails?.solarKwdc}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Existing Solar - kW-AC"}>
            {projectDetails?.solarKwac}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Existing Solar - Annual Production"}>
            {projectDetails?.solarAnnualProduction}
          </ProjectInfoItem>
          <ProjectInfoItem title={"New Solar - kW-DC"}>
            {projectDetails?.newSolarKwdc}
          </ProjectInfoItem>
          <ProjectInfoItem title={"New Solar - kW-AC"}>
            {projectDetails?.newSolarKwac}
          </ProjectInfoItem>
          <ProjectInfoItem title={"New Solar - Annual Production"}>
            {projectDetails?.newSolarAnnualProduction}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Contractor"}>
            {projectDetails?.contractor}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Battery Manufacturer"}>
            {projectDetails?.batteryOem}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Battery Product"}>
            {projectDetails?.batteryModel}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Battery Capacity (kWh)"}>
            {calculateUsableBatteryCapacity(
              projectDetails?.usableCapacity,
              projectDetails?.numberOfBatteries,
            )}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Summer enrollment"}>
            {projectDetails?.summerEnrolment}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Winter enrollment"}>
            {projectDetails?.winterEnrolment}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Participation"}>
            {projectDetails?.participation === "weekdays_weekends"
              ? "Weekdays & weekends"
              : `${capitalizeFirstLetter(projectDetails?.participation ?? "")} Only`}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Upfront Incentive Payment"}>
            {formatCurrency(
              Number(projectDetails?.upfrontIncentivePayment) / 100,
            )}
          </ProjectInfoItem>
          <ProjectInfoItem title={"Participant enrollment"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"body1Medium"}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {projectDetails?.participantEnrollment?.fileName}
              </Typography>
              <StyledIconButton
                onClick={() =>
                  downloadFile(
                    `project/document/download/${projectDetails?.participantEnrollment?.id}`,
                    `participant_enrollment_${projectDetails?.participantEnrollment?.id}`,
                  )
                }
              >
                <SVGGoTo />
              </StyledIconButton>
            </Box>
          </ProjectInfoItem>
        </BoxInfoWrapper>
        <Box
          sx={{
            ...(downSm && { position: "absolute", top: "20px", right: "16px" }),
          }}
        >
          {allowEditProject(role, projectDetails.enrolmentStatus) && (
            <EditProjectModal
              iconOnly
              project={projectDetails}
              getFilesReq={getFilesReq}
              getUpdatedProjects={() => getProjectDetails(projectDetails?.id)}
            />
          )}
        </Box>
      </Box>
      <BoxInfoWrapper sx={{ marginTop: "40px" }}>
        <ProjectInfoItem title={"Incentive status"}>
          <BadgeSelect
            readOnly={true}
            currentSelectedItem={projectDetails?.incentiveStatus as string}
            items={INCENTIVE_STATUS_ITEMS}
            badgeVariantFn={getFileStorageBadgeVariant}
          />
        </ProjectInfoItem>
        <ProjectInfoItem title={"Enrollment status"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StyledBadge
              sx={{ display: "inline-flex", textWrap: "nowrap", width: "100%" }}
              {...getEnrollmentBadgeVariant(
                projectDetails?.enrolmentStatus as any,
              )}
            >
              {replaceUnderscoresWithSpaces(
                capitalizeFirstLetter(projectDetails?.enrolmentStatus ?? ""),
              )}
            </StyledBadge>
          </Box>
        </ProjectInfoItem>
        {projectDetails.operationalizedDate && (
          <ProjectInfoItem title="Operationalized Date">
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography
                variant={"body1Medium"}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  color: theme.palette.text.primary,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {dayjs(projectDetails.operationalizedDate).format("MMMM D, YYYY h:mm a")}
              </Typography>
            </Box>
          </ProjectInfoItem>
        )}

      </BoxInfoWrapper>
    </Card>
  );
};

export default ProjectInfoCard;

const ProjectInfoItem = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        variant={"body1Medium"}
        sx={{ color: "textLightGray3", whiteSpace: "nowrap" }}
      >
        {title}
      </Typography>
      {typeof children === "string" ? (
        <Typography
          variant={"body1Medium"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          }}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
};
