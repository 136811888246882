import { Button } from "@mui/material";
import DueDateModal from "@/pages/ProjectsPage/components/DueDateModal.tsx";
import { FC, useState } from "react";
import {ProjectRecord} from "@/types";

type Props = {
    projectId: ProjectRecord["id"];
}

const ExtendProjectDueDate:FC<Props> = ({ projectId }) => {
    const [dueDateProjectId, setDueDateProjectId] = useState(0);

    const handleCloseDueDateExtended = () => {
        setDueDateProjectId(0);
    };

    const handleOpenDueDateModal = () => {
        setDueDateProjectId(projectId)
    }

    return (
        <>
            <Button
                sx={{
                    width: "fit-content",
                    marginLeft: "auto",
                    marginBottom: "24px"
                }}
                variant="contained"
                onClick={handleOpenDueDateModal}
            >
                Extend due date
            </Button>
            <DueDateModal
                projectId={dueDateProjectId}
                onClose={handleCloseDueDateExtended}
            />
        </>
    )
}

export default ExtendProjectDueDate;