import React, { FormEventHandler } from "react";
import { Box, Button } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FileRecord, ProjectRecord } from "@/types";
import { INCENTIVE_STATUS } from "@/constants";
import FirstProjectForm from "@/pages/ProjectDetailsPage/components/FirstProjectForm.tsx";
import SecondProjectForm from "@/pages/ProjectDetailsPage/components/SecondProjectForm.tsx";
import ExtendProjectDueDate from "@/pages/ProjectDetailsPage/components/ExtendProjectDueDate.tsx";

const ProjectForm = ({
  handleSubmit,
  handleClose,
  isEdit = false,
  handleStep,
  step,
  participantEnrollment,
  contract,
  systemSizingTool,
  incentiveStatus,
  enrollmentStatus,
  isAdmin,
  projectId,
}: {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleClose: () => void;
  handleStep: (step: 1 | 2) => void;
  step: 1 | 2;
  isEdit?: boolean;
  participantEnrollment?: FileRecord;
  systemSizingTool?: FileRecord;
  contract?: FileRecord;
  incentiveStatus?: INCENTIVE_STATUS | undefined;
  enrollmentStatus?: ProjectRecord["enrolmentStatus"] | undefined;
  isAdmin?: boolean;
  projectId?: ProjectRecord["id"];
}) => {
  const {
    control,
    formState,
  } = useFormContext();

  const { isValid } = formState

  const watchedFields = useWatch({ control });

  const {
    address,
    customerName,
    upfrontIncentivePayment,
    contractor,
    city,
    zipCode,
    accountHash,
    accountEmail,
    solarKwdc,
    solarKwac,
    solarAnnualProduction,
    existingInverter,
    newSolarKwdc,
    newSolarKwac,
    newSolarAnnualProduction,
    winterEnrolment,
    summerEnrolment,
    participation,
    numberOfBatteries,
    participantEnrollment: participantEnrollmentField,
    systemSizingTool: systemSizingToolField,
  } = watchedFields;

  const isFirstStepDisabled =
    !customerName ||
    !address ||
    !city ||
    !zipCode ||
    !accountHash ||
    !accountEmail ||
    !existingInverter ||
    !newSolarKwdc ||
    !newSolarKwac ||
    !newSolarAnnualProduction ||
    !solarKwac ||
    !solarAnnualProduction ||
    !solarKwdc ||
    !upfrontIncentivePayment;

  const isSecondStepDisabled =
    !contractor ||
    !summerEnrolment ||
    !winterEnrolment ||
    !participation ||
    !participantEnrollmentField ||
    !systemSizingToolField ||
    !numberOfBatteries;
  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        marginTop: "24px",
      }}
      onSubmit={handleSubmit}
    >
      {isAdmin && incentiveStatus && projectId && <ExtendProjectDueDate projectId={projectId} />}
      <Box sx={{ height: "100%", overflow: "auto" }}>
        {step === 1 && (
          <FirstProjectForm
            enrollmentStatus={enrollmentStatus}
            incentiveStatus={incentiveStatus}
            isEdit={isEdit}
          />
        )}
        {step === 2 && (
          <SecondProjectForm
            participantEnrollment={participantEnrollment}
            contract={contract}
            systemSizingTool={systemSizingTool}
          />
        )}
      </Box>
      <Box
        sx={{
          marginTop: "32px",
          display: "flex",
          gap: "8px",
          width: "100%",
        }}
      >
        <Button
          variant={"outlined"}
          sx={{ width: "100%" }}
          onClick={() => {
            step === 1 ? handleClose() : handleStep(1);
          }}
        >
          {step === 1 ? "Cancel" : "Back"}
        </Button>
        {step === 1 && (
          <Button
            variant={"contained"}
            sx={{ width: "100%" }}
            onClick={() => handleStep(2)}
            disabled={isFirstStepDisabled}
          >
            Continue
          </Button>
        )}
        {step === 2 && (
          <Button
            variant={"contained"}
            sx={{ width: "100%" }}
            type={"submit"}
            disabled={!isValid || isSecondStepDisabled}
          >
            {isEdit ? "Save" : "Create"}
          </Button>
        )}
      </Box>
    </form>
  );
};

export default ProjectForm;
