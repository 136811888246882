import React, { useMemo } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import FileComponent from "@/components/FileComponent";
import FormSelect from "@/components/FormSelect";
import FormInput from "@/components/FormInput";
import SVGLock from "@/assets/icons/SVGLock.tsx";
import { Control } from "react-hook-form";
import { FILE_TYPE_ARRAY, FILE_TYPES, OEM_FILE_TYPES } from "@/constants";
import ProjectSelect from "@/components/UploadProjectFile/ProjectSelect.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import FormCurrencyInput from "@/components/FormCurrencyInput";

const renderSelectItem = (value: string) => {
  return (
    <MenuItem sx={{ borderRadius: "12px" }} value={value}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {value}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const FileTypeForm = ({
  fileInfo,
  control,
  fileType,
  showProjectSelect = false,
  showSerialNumberInput,
  showPerformanceReportInput,
}: {
  fileInfo: { name: string; size: number };
  control: Control<any, any>;
  fileType: string;
  showProjectSelect?: boolean;
  showSerialNumberInput: boolean;
  showPerformanceReportInput: boolean;
}) => {
  const role = useAuthStore((state) => state.role);

  const fileTypesItems = useMemo(() => {
    return role === "oem" ? OEM_FILE_TYPES : FILE_TYPE_ARRAY;
  }, [role]);

  const contractorFileTypesITems = useMemo(() => {
    return role === 'contractor' ? FILE_TYPE_ARRAY.filter((item) => item !== 'Performance report') : FILE_TYPE_ARRAY;
  }, [role]);

  const isSerialNumberType =
    fileType === FILE_TYPES.SERIAL_NUMBER && showSerialNumberInput;
  const isPerformanceReport =
    fileType === FILE_TYPES.PERFORMANCE_REPORT && showPerformanceReportInput;
  return (
    <Box>
      <Box sx={{ paddingBottom: "16px" }}>
        <FileComponent fileInfo={fileInfo} />
      </Box>
      {showProjectSelect && (
        <ProjectSelect
          name={"project"}
          control={control}
          placeholder={"Select project"}
          controlProps={{ rules: { required: "Required" } }}
        />
      )}
      <FormSelect
        placeholder={"Select document type"}
        name={"type"}
        control={control}
        controlProps={{ rules: { required: "Required" } }}
        label={"Document Type"}
      >
        {role === 'contractor' ? contractorFileTypesITems.map((el) => renderSelectItem(el)) : fileTypesItems.map((el) => renderSelectItem(el))}
        {/* {fileTypesItems.map((el) => renderSelectItem(el))} */}
      </FormSelect>
      {fileType === "Other" && (
        <FormInput
          name={"otherFileType"}
          control={control}
          label={"Other document type"}
          placeholder={"Specify document type"}
        />
      )}
      {isSerialNumberType && (
        <FormInput
          name={"serialNumber"}
          control={control}
          label={"Serial Number"}
          placeholder={"Specify serial number"}
          controlProps={{
            ...(isSerialNumberType && {
              rules: {
                required: "Required",
                validate: {
                  maxLength: (value: string) =>
                    value.trim().length <= 50 ||
                    "Serial number must not exceed 50 characters",
                },
              },
            }),
          }}
        />
      )}
      {isPerformanceReport && (
        <FormCurrencyInput
          name={"quarterlyPerformanceIncentiveValue"}
          control={control}
          label={"Quarterly performance incentive value"}
          placeholder={"$00.00"}
          maxValue={100000}
          defaultValue={""}
          controlProps={{
            ...(isPerformanceReport && { rules: { required: "Required" } }),
          }}
        />
      )}
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <SVGLock />
        <Typography variant={"helperText"} sx={{ color: "textGray4" }}>
          All uploaded files are securely protected by our software, ensuring
          data confidentiality and preventing unauthorized access.
        </Typography>
      </Box>
    </Box>
  );
};

export default FileTypeForm;
