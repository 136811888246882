/* eslint-disable @typescript-eslint/no-explicit-any */
import SVGEmail from "@/assets/icons/SVGEmail.tsx";
import {Alert, Button} from "@mui/material";
import {sendPerformanceIncentiveEmailReq} from "@/api/projectsApi";
import React, {FC, useState} from "react";
import Snackbar from "@mui/material/Snackbar";

type NotificationMessage = {
    message: string;
    severity: "error" | "success";
}

type Props = {
    projectIds: number[] | undefined;
}

const SendPerformanceIncentiveEmailButton:FC<Props> = ({ projectIds }) => {
    const [notificationMessage, setNotificationMessage] = useState<NotificationMessage | null>(null)

    const sendPerformanceIncentiveEmailForProject = (projectIds: number[]) => async () => {
        try {
            const response = await sendPerformanceIncentiveEmailReq(projectIds);

            setNotificationMessage({
                message: response?.data?.message,
                severity: "success",
            });
        } catch (e: any) {
            setNotificationMessage({
                message: e?.response?.data?.error || "An unexpected error occurred.",
                severity: "error",
            });
        }
    };

    const handleClose = () => {
        setNotificationMessage(null);
    };

    if (!projectIds) return null;

    return (
        <>
            <Button
                variant="outlined"
                style={{ flexShrink: 0 }}
                onClick={sendPerformanceIncentiveEmailForProject(projectIds)}
                startIcon={<SVGEmail color="#1976d2" />}
            >
                Send Performance Incentive Email
            </Button>
            {notificationMessage && (
                <Snackbar
                    open={Boolean(notificationMessage)}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={notificationMessage?.severity}>
                        {notificationMessage?.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}

export default SendPerformanceIncentiveEmailButton;